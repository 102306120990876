.zinnia {
  text-align: center;
  * html & {
    legend {
      margin: 0px -8px 16px 0;
      padding: 0;
    }
  }

  html>& {
    p code {
      *white-space: normal;
    }
  }

  .container {
    text-align: left
  }

  sup {
    vertical-align: text-top; }
  sub {
    vertical-align: text-bottom; }
  hr {
    margin: -8px auto 11px; }
  img {
    -ms-interpolation-mode: bicubic; }
  fieldset {
    padding-top: 0; }
  legend {
    margin-top: -0.2em;
    margin-bottom: 1em;
    margin-left: -0.5em; }
  fieldset, #IE8#HACK {padding-top:1.4em;}
  legend, #IE8#HACK {margin-top:0;margin-bottom:0;}
  textarea {
    overflow: auto; }
  label {
    position: relative;
    top: -0.25em; }
  input {
    &.text {
      margin: 0.5em 0;
      background-color: white;
      border: 1px solid #bbbbbb;
      &:focus {
        border: 1px solid #666666; } }
    &.title {
      margin: 0.5em 0;
      background-color: white;
      border: 1px solid #bbbbbb;
      &:focus {
        border: 1px solid #666666; } }
    &.checkbox {
      position: relative;
      top: 0.25em; }
    &.radio {
      position: relative;
      top: 0.25em; }
    &.button {
      position: relative;
      top: 0.25em; } }
  textarea {
    margin: 0.5em 0; }
  select {
    margin: 0.5em 0; }
  button {
    position: relative;
    top: 0.25em; }
}
